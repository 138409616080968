* {
    font-family: "Montserrat", sans-serif;
}

body {
    overflow: hidden scroll;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Montserrat", sans-serif;
}

@keyframes slide {
    from {
        opacity: 0;
        left: 10%;
    }
    to {
        opacity: 1;
        left: 5%;
    }
}

@keyframes fade {
    0% {
        opacity: 0.5;
    }
    50% {
        left: 0.8;
    }
    100% {
        left: 1;
    }
}
